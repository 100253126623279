import * as React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

export const AlertDialog = ({ open, body, onClose }) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">
                {body}
            </DialogTitle>
            {body !== "Logging out..." &&
                <DialogActions>
                    <Button onClick={onClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
}