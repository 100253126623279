import React, { useEffect, useState } from "react";
// import { useForm, Controller } from 'react-hook-form';
// import axios from 'axios';
import * as LoginUtils from '../../utils/LoginUtils';
import GoogleButton from '../../utils/GoogleButton';
import { useNavigate } from 'react-router-dom';
import * as ErrorHandlerUiUtils from '../../utils/ErrorHandlerUIUtils';
import { AlertDialog } from '../../sub_components/AlertDialog';

import { useTheme, Box, /*Alert, TextField, Typography, InputAdornment, IconButton*/ } from "@mui/material";
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ButtonComponent } from '../../sub_components/ButtonComponent';
import { HeaderComponent } from '../../sub_components/HeaderComponent';
import { MainContainer } from "../Containers/MainContainer";

// import { makeStyles } from '@mui/styles';

const Login = () => {
    const navigate = useNavigate();
    // const theme = useTheme();

    const [state, setState] = useState({
        isShowSnackbar: false,
        snackbarMessage: "",
        isNavigateBack: false
    })

    // const { handleSubmit, control } = useForm();

    // const isFormFieldsValid = (data) => {
    //     if (!data.username || !data.password || data.username.trim() === "" || data.password.trim() === "") {
    //         return false;
    //     }
    //     if (data.username.split("@")[1] != "eyeviation.com") {
    //         setState({isShowSnackbar: true, snackbarMessage: "Only @eyeviation.com users are allowed.", isNavigateBack: false});
    //         return false;
    //     }
    //     return true;
    // }

    // const onSubmit = async (data) => {
    //     if (!isFormFieldsValid(data)) {
    //         return;
    //     }

    //     // //to prevent double clicks
    //     // let currentBtn = this.btn.current;
    //     // currentBtn.setAttribute("disabled", "disabled");

    //     try {
    //         console.log("Entered login");

    //         let userDetails = {
    //             username: data.username,
    //             password: data.password,
    //             token: ""
    //         }

    //         let userLoginDetails = { username: userDetails.username, password: userDetails.password };

    //         const response = await axios.post("https://build.eyeviation.com/be/users/login", userLoginDetails);
    //         const serverResponse = response.data;
    //         userDetails.token = serverResponse.token;

    //         LoginUtils.setUsersCache(userDetails.token, userDetails.username);

    //         LoginUtils.setTokenToAxiosHeaders(userDetails.token);

    //         navigate("downloads");
    //     }
    //     catch (error) {
    //         ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, state, navigate);
    //     }
    //     // finally {
    //     //     currentBtn.removeAttribute("disabled");
    //     // }
    // }

    // const [isShowPassword, setIsShowPassword] = useState(false);

    // const handleClickShowPassword = () => {
    //     setIsShowPassword(!isShowPassword);
    // };

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    useEffect(() => {
        //in order to 'survive' page refresh
        let token = sessionStorage.getItem("token");
        LoginUtils.setTokenToAxiosHeaders(token);

        try {
            //if user is already logged in, we send him back to the main page
            if (token != null) {
                navigate(-1);
            }
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, navigate);
        }
    }, []);

    const onClose = () => {
        setState({ isShowSnackbar: false });
    }

    // const useStyles = makeStyles({
    //     form: {
    //         display: 'flex',
    //         flexDirection: 'column',
    //         justifyContent: 'center',
    //         alignItems: 'flex-start',
    //         paddingTop: theme.spacing(2),
    //         width: '100%',

    //         '& .MuiTextField-root': {
    //             margin: '.3rem 0 1.5rem 0',
    //             width: '300px',
    //         },
    //         "& .MuiFilledInput-root": {
    //             background: '#00000000'
    //         }
    //     }
    // })

    // const classes = useStyles();

    return (
        <MainContainer>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <HeaderComponent text="welcome" /><br/>
                <Box>
                    {/* <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="username"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label="Username"
                                    variant="filled" size="small"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            rules={{ required: 'Username is required' }}
                        />

                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label="Password"
                                    variant="filled" size="small"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    type={isShowPassword ? 'text' : 'password'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {isShowPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>)
                                    }}
                                />
                            )}
                            rules={{ required: 'Password is required' }}
                        />

                        <ButtonComponent type="submit" fontColor={theme.palette.grayDark} text="login" width="100%" />
                    </form> */}
                    <GoogleButton setState={setState}/>
                </Box>

                <AlertDialog open={state.isShowSnackbar} body={state.snackbarMessage} onClose={onClose} />
            </Box>
        </MainContainer>
    )
}

export default Login;