import React from "react";
import { useTheme, Button } from "@mui/material";

export const ButtonComponent = ({
    text,
    fontColor,
    width,
    type,
    onClick
}) => {
    const theme = useTheme();
    return (
        <Button type={type && type} onClick={onClick && onClick} variant="contained" size={'medium'} sx={{ width: width && width, mt: 2, mb: 2, backgroundColor: theme.palette.blueLight, color: fontColor, "&:hover": { backgroundColor: theme.palette.blueDark } }}>
            {text}
        </Button>
    )
}