import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as LoginUtils from '../../utils/LoginUtils';
import * as ErrorHandlerUiUtils from '../../utils/ErrorHandlerUIUtils';
import { AlertDialog } from '../../sub_components/AlertDialog';
import { CssBaseline, useTheme, Drawer, Link, Box, List, Toolbar, Divider } from "@mui/material";

export const SideBar = ({ menuItems }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [state, setState] = useState({
        isShowSnackbar: false,
        snackbarMessage: "",
        isNavigateBack: false
    })

    useEffect(async () => {
        if (window.location.pathname.split("/")[1] != "downloads") {
            //in order to 'survive' page refresh
            let token = sessionStorage.getItem("token");
            LoginUtils.setTokenToAxiosHeaders(token);

            try {
                //if user is already logged in, we send him back to the main page
                if (token != null) {
                    const response = await axios.get("https://build.eyeviation.com/be/users/details");
                    if (window.location.pathname.split('/')[1] != "" && window.location.pathname.split('/')[1] != "downloads") {
                        if (response.data.isAuth != 1) {
                            setState({
                                isShowSnackbar: true,
                                snackbarMessage: "You are not authorized to access this page.",
                                isNavigateBack: true
                            })
                        }
                    }
                }
            }
            catch (error) {
                ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, navigate);
            }
        }
    }, [window.location.pathname]);

    const onLogOutClicked = async () => {
        //to prevent double clicks
        // let currentBtn = this.btn.current;
        // currentBtn.setAttribute("disabled", "disabled");

        try {
            //clear user from server cache
            let token = sessionStorage.getItem("token");
            LoginUtils.setTokenToAxiosHeaders(token);
            
            await axios.post("https://build.eyeviation.com/be/users/logout");

            sessionStorage.removeItem("token");
            sessionStorage.removeItem("username");

            let message = "Logging out...";
            ErrorHandlerUiUtils.showSnackbar(message, setState);
            ErrorHandlerUiUtils.hideSnackbar(setState);
            ErrorHandlerUiUtils.sendBackToHomePage(navigate);
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, navigate);
        }
    }

    const onClose = () => {
        if (state.isNavigateBack) navigate(-1);
        setState({ isShowSnackbar: false, isNavigateBack: false });
    }

    return (
        <Box sx={{ display: 'flex', zIndex: 1 }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: 330,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 330,
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.grayLight
                    },
                }}
                variant="permanent"
                anchor="left">
                <Toolbar />
                <List sx={{ display: 'flex', flexDirection: 'column', height: '30%', justifyContent: 'space-around', paddingTop: '120px' }}>
                    {menuItems.map((item, index) => {
                        return <Box key={index} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase' }}>
                            <Link sx={{
                                width: '85%',
                                padding: '1.5rem 2rem',
                                color: theme.palette.white,
                                fontSize: theme.fontSize.p,
                                textDecoration: 'none',
                                display: 'flex',
                                color: item.href === window.location.pathname ? theme.palette.blueLight : theme.palette.white,
                                transition: 'color .2s ease-in',
                                "&:hover": {
                                    color: theme.palette.blueLight,
                                }
                            }}
                                onClick={() => item.href ? navigate(item.href) : onLogOutClicked()}>
                                {item.icon && <span style={{ marginRight: '.5rem' }}>{item.icon}</span>}
                                {item.text}
                            </Link>
                            <Divider width={'90%'} />
                        </Box>
                    })}
                </List>
            </Drawer>
            <AlertDialog open={state.isShowSnackbar} body={state.snackbarMessage} onClose={onClose} />
        </Box>
    )
}