import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, AppBar, Box, Container, Toolbar } from "@mui/material";

export const Header = ({ logo }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Fragment>
            <AppBar sx={{ zIndex: 2, backgroundColor: theme.palette.grayLight, height: '60px' }} elevation={4} >
                <Container disableGutters={false} maxWidth={"100%"}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', width: '99%' }}>
                        <Box onClick={() => navigate('/')}>
                            <img src={logo} alt={logo.split('/')[1].split('.')[0]} width='100px' />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Fragment>
    )
}