import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Downloads from "./components/Downloads/Downloads";
import License from "./components/License/License";
import Licenses from "./components/Licenses/Licenses";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/downloads/*" element={<Downloads />} />
          <Route path="/create-license" exact element={<License />} />
          <Route path="/licenses" exact element={<Licenses />} />

          <Route path="*" exact element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;