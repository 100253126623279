import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MainContainer } from "../Containers/MainContainer";
import PropTypes from 'prop-types';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { useTheme, Box, Typography, Tabs, Tab, Divider, Checkbox } from "@mui/material";

import { ButtonComponent } from '../../sub_components/ButtonComponent';
import { HeaderComponent } from '../../sub_components/HeaderComponent';
import { AlertDialog } from '../../sub_components/AlertDialog';
import * as LoginUtils from '../../utils/LoginUtils';
import * as ErrorHandlerUiUtils from '../../utils/ErrorHandlerUIUtils';

const Downloads = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [currentParent, setCurrentParent] = useState("diana");
    const [tabIndex, setTabIndex] = useState(0);
    const [versions, setVersions] = useState([]);
    const [currentVersion, setCurrentVersion] = useState([]);
    const [isAuth, setIsAuth] = useState(false);

    const [state, setState] = useState({
        isShowSnackbar: false,
        snackbarMessage: "",
        isNavigateBack: false
    })
    const [isShowPreloader, setIsShowPreloader] = useState(false);

    useEffect(async () => {
        navigate({
            pathname: `/downloads/${currentParent}`
        })

        let versions = await getVersions(currentParent);
        setVersions(versions);
        console.log("versions", versions);
        setCurrentVersion(versions.filter(v => v.parent === currentParent));

        //in order to 'survive' page refresh
        let token = sessionStorage.getItem("token");
        LoginUtils.setTokenToAxiosHeaders(token);

        try {
            //if user is already logged in, we send him back to the main page
            if (token != null) {
                const response = await axios.get("https://build.eyeviation.com/be/users/details");
                if (response.data.isAuth == 1) {
                    setIsAuth(true);
                }
            }
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, navigate);
        }
    }, []);

    const getVersions = async (parent) => {
        try {
            const response = await axios.get(`https://build.eyeviation.com/be/versions/${parent}`);
            return response.data;
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, state, navigate);
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component={"span"}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    async function a11yProps(index, parent) {
        navigate({
            pathname: `/downloads/${parent}`
        })

        setTabIndex(index);

        let versions = await getVersions(parent);
        setVersions(versions);
        setCurrentVersion(versions.filter(v => v.parent === parent));

        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                setCurrentParent("diana");
                break;

            case 1:
                setCurrentParent("grace");
                break;

            case 2:
                setCurrentParent("juno");
                break;

            case 3:
                setCurrentParent("rubi");
                break;

            case 4:
                setCurrentParent("tsvt");
                break;

        }
    };

    const onClose = () => {
        setState({ isShowSnackbar: false });
    }

    const onDownloadClicked = async (versionSrc) => {
        try {
            setIsShowPreloader(true);
            const response = await axios.post(`https://build.eyeviation.com/be/versions/${versionSrc}`, '', { responseType: 'arraybuffer' });
            setIsShowPreloader(false);
            fileDownload(response.data, versionSrc);
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, state, navigate);
        }
    }

    async function handleIsStableChange(item) {
        let index = versions.findIndex(version => version.filename == item.filename);
        let newVersions = [...versions];

        if (item.is_stable === 1) {
            item.is_stable = 0;
        } else {
            item.is_stable = 1;
        }

        newVersions.map(v => { if (v.is_stable === 1 && v.num != item.num) v.is_stable = 0 });
        
        newVersions[index] = item;
        setVersions(newVersions);
        await axios.post(`https://build.eyeviation.com/be/versions/`, item);
    }

    return (
        <MainContainer>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
                <HeaderComponent text="downloads" />
                {isShowPreloader && <div className="loaderBox"><div className="loader"></div><span>downloading...</span></div>}
                <Box>
                    <Tabs indicatorColor={'primary'} value={value} onChange={handleChange} sx={{ marginTop: 3, marginBottom: 1 }}>
                        <Tab label="Diana" onClick={() => a11yProps(0, "diana")} sx={{ height: '1rem', borderRight: '2px solid', borderColor: 'divider', padding: '0 2rem' }} />
                        <Tab label="Grace" onClick={() => a11yProps(1, "grace")} sx={{ height: '1rem', borderRight: '2px solid', borderColor: 'divider', padding: '0 2rem' }} />
                        <Tab label="Juno" onClick={() => a11yProps(2, "juno")} sx={{ height: '1rem', borderRight: '2px solid', borderColor: 'divider', padding: '0 2rem' }} />
                        <Tab label="Rubi" onClick={() => a11yProps(3, "rubi")} sx={{ height: '1rem', borderRight: '2px solid', borderColor: 'divider', padding: '0 2rem' }} />
                        <Tab label="tsvt analysis" onClick={() => a11yProps(4, "tsvt")} sx={{ height: '1rem', padding: '0 2rem' }} />
                    </Tabs>
                </Box>
                {currentVersion.length > 0 ?
                    <TabPanel value={value} index={tabIndex}>
                        {/* <HeaderComponent text='Latest unstable version' fontSize={theme.fontSize.p} />
                    {currentVersion.filter(item => item.type === 'unstable').map((item, index) => {
                        return <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ width: 300 }}>
                                {item.num}
                            </Typography>
                            <ButtonComponent text="download" onClick={() => onDownloadClicked(item.src)} />
                        </Box>
                    })}

                    <Divider sx={{ margin: '1rem 0' }} /> */}

                        <HeaderComponent text='Stable version' fontSize={theme.fontSize.p} />
                        {currentVersion.filter(item => item.is_stable === 1).map((item, index) => {
                            return <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {isAuth && <Checkbox checked={item.is_stable === 1 ? true : false} onChange={() => handleIsStableChange(item)} />}
                                <Typography sx={{ width: 300 }}>
                                    {item.num}
                                </Typography>
                                <ButtonComponent text="download" onClick={() => onDownloadClicked(item.src)} />
                            </Box>
                        })}

                        <Divider sx={{ margin: '1rem 0' }} />

                        <HeaderComponent text='Other Versions' fontSize={theme.fontSize.p} />
                        {currentVersion.filter(item => item.is_stable === 0).map((item, index) => {
                            return <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {isAuth && <Checkbox checked={item.is_stable === 1 ? true : false} onChange={() => handleIsStableChange(item)} />}
                                <Typography sx={{ width: 300 }}>
                                    {item.num}
                                </Typography>
                                <ButtonComponent text="download" onClick={() => onDownloadClicked(item.src)} />
                            </Box>
                        })}
                    </TabPanel>
                    :
                    <TabPanel value={value} index={tabIndex}>
                        <HeaderComponent text='No versions' fontSize={theme.fontSize.p} />
                    </TabPanel>
                }
                <AlertDialog open={state.isShowSnackbar} body={state.snackbarMessage} onClose={onClose} />
            </Box>
        </MainContainer>
    )
}

export default Downloads;