import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    direction: "ltr",
    fontFamily: ["Open Sans, sans-serif"].join(","),
    fontSize: {
        p: '1rem', //16px
        h1: '4.1rem',//82px
        h2: '3.7rem',//74px
        h3: '3rem',//60px
        h4: '1.75rem',
        h5: '1.5rem',//108px
        h6: '1.3rem',
        btnL: '1.15rem',
        btnM: '1rem',
        btnS: '0.9rem'
    },
    palette: {
        mode: 'dark',
        background: "#151515",
        blueLight: "#20C4FF",
        blueDark: "#12A7DD",
        grayLight: "#262626",
        grayDark: "#151515",
        white: "#FEFEFE"
    },
});