import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { useTheme, Box, InputLabel, TextField, Button, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import axios from 'axios';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';

import { AlertDialog } from '../../sub_components/AlertDialog';
import * as ErrorHandlerUiUtils from '../../utils/ErrorHandlerUIUtils';
import { MainContainer } from "../Containers/MainContainer";
import { HeaderComponent } from '../../sub_components/HeaderComponent';

const License = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { handleSubmit, control, reset, setValue, register } = useForm();

    const [isShowCalendar, setIsShowCalendar] = useState(false);
    const [fileInputLabel, setFileInputLabel] = useState("");
    const [chosenFile, setChosenFile] = useState(null);

    const [state, setState] = useState({
        isShowSnackbar: false,
        snackbarMessage: "",
        isNavigateBack: false
    })

    const onSubmit = (data) => {
        if (data.expiryDate === "other") {
            data.expiryDate = data.selectedDate;
        }
        delete data.selectedDate;

        let expiryDate = setExpiryDate(data.expiryDate);

        let licenseData = {
            expiryTimestamp: expiryDate ? moment(expiryDate).unix() : 8640000000000000,
            expiryDate: expiryDate,
            reason: data.reason,
        }

        addNewLicenses(licenseData);

        initFormOptions();
    };

    const setExpiryDate = (expiryDate) => {
        let date;

        if (expiryDate === "unlimited") {
            date = null;
        } else if (expiryDate === "month") {
            date = moment(new Date()).add(1, 'M').format('YYYY-MM-DD');
        } else {
            date = moment(expiryDate).format('YYYY-MM-DD');
        }

        return date;
    }

    const addNewLicenses = async (licenseData) => {
        const data = new FormData();
        data.append('file', chosenFile);

        try {
            await axios.post("https://build.eyeviation.com/be/licenses/upload", data).then(res => {
                licenseData.filename = res.data;
                axios.post("https://build.eyeviation.com/be/licenses/", licenseData).then(file => {
                    fileDownload(file.data, res.data.split(".")[0] + ".dk");
                });
            });
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, state, navigate);
        }
    }

    const initFormOptions = () => {
        setIsShowCalendar(false);
        reset();
    };

    const toggleCalendar = (value) => {
        setIsShowCalendar(value === "other");
    }

    const useStyles = makeStyles({
        form: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingTop: theme.spacing(2),
            minWidth: "25rem",
            maxWidth: "28rem",

            "& .MuiTextField-root": {
                margin: ".3rem 0 1.5rem 0",
                width: "100%",
            },
            "& .MuiInputLabel-root": {
                fontSize: theme.fontSize.p,
            },
            "& .MuiCalendarPicker-root": {
                backgroundColor: theme.palette.grayLight,
            },
            "& .css-dhopo2": {
                minHeight: '215px'
            }
        }
    });

    const classes = useStyles();

    const onClose = () => {
        setState({ isShowSnackbar: false });
    }

    return (
        <MainContainer>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
                <HeaderComponent text="Generate a license" />

                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            component="label">
                            Choose file
                            <input
                                hidden
                                type="file"
                                name="file"
                                onChange={(event) => { setChosenFile(event.target.files[0]); setFileInputLabel(event.target.files[0].name) }}
                            />
                        </Button>
                        <span style={{ marginLeft: "1rem" }}>{fileInputLabel}</span>
                    </Box>
                    <br />

                    <InputLabel>Reason</InputLabel>
                    <Controller
                        name="reason"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...register("reason")}
                                label=" "
                                variant="outlined"
                                size="small"
                                multiline
                                minRows={4}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                placeholder=""
                                InputLabelProps={{
                                    shrink: false,
                                }}
                            />
                        )}
                        rules={{ required: "Reason is required" }}
                    />

                    <InputLabel>Expiry date</InputLabel>
                    <Controller
                        name="expiryDate"
                        control={control}
                        defaultValue="unlimited"
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <div>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={value}
                                    onChange={(event, value) => { toggleCalendar(value); onChange(value) }}
                                >
                                    <FormControlLabel {...register("expiryDate")} value="unlimited" control={<Radio />} label="Unlimited" />
                                    <FormControlLabel {...register("expiryDate")} value="month" control={<Radio />} label="One month" />
                                    <FormControlLabel {...register("expiryDate")} value="other" control={<Radio />} label="Other" />
                                </RadioGroup>

                                {isShowCalendar &&
                                    <Controller
                                        name="selectedDate"
                                        control={control}
                                        defaultValue={new Date()}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <CalendarPicker
                                                    {...register("selectedDate")}
                                                    format={"DD/mm/yyyy"}
                                                    date={value}
                                                    value={value}
                                                    minDate={new Date()}
                                                    onChange={(newValue) => onChange(newValue)} />
                                            </LocalizationProvider>
                                        )}
                                        rules={{ required: "Expiry date is required" }}
                                    />}
                            </div>
                        )}
                        rules={{ required: "Expiry date is required" }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        size={"large"}
                        sx={{
                            marginTop: '1rem',
                            backgroundColor: theme.palette.blueLight,
                            width: "100%",
                            "&:hover": {
                                backgroundColor: theme.palette.blueDark,
                            },
                            "&:active": {
                                backgroundColor: theme.palette.blueDark,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: theme.palette.blueLight,
                                color: theme.palette.grayDark,
                            },
                        }}>
                        Generate
                    </Button>
                </form>

                <AlertDialog open={state.isShowSnackbar} body={state.snackbarMessage} onClose={onClose} />
            </Box>
        </MainContainer>
    )
}

export default License;