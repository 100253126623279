import React, { useState, useEffect } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import moment from "moment";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';

import { useTheme, Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from "@mui/material";

import { MainContainer } from "../Containers/MainContainer";
import { HeaderComponent } from '../../sub_components/HeaderComponent';
import { AlertDialog } from '../../sub_components/AlertDialog';
import * as ErrorHandlerUiUtils from '../../utils/ErrorHandlerUIUtils';

const AllLicenses = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [licenses, setLicenses] = useState([]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [state, setState] = useState({
        isShowSnackbar: false,
        snackbarMessage: "",
        isNavigateBack: false
    })

    useEffect(async () => {
        //fix page refresh
        setLicenses(await getLicenses());
    }, []);

    const getLicenses = async () => {
        try {
            const response = await axios.get("https://build.eyeviation.com/be/licenses/");

            for (let license of response.data) {
                license.creationDate = moment(license.creationDate).format("LL");
                license.expiryDate =  license.expiryDate ? moment(license.expiryDate).format("LL") : "unlimited";
            }

            return response.data;
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, state, navigate);
        }
    }

    const onDownloadClicked = async (filename) => {
        try {
            let dkFile = filename.split(".")[0] + ".dk";
            const response = await axios.post(`https://build.eyeviation.com/be/licenses/${dkFile}`);
            fileDownload(response.data, dkFile);
        }
        catch (error) {
            ErrorHandlerUiUtils.handleErrorsOnUi(error, setState, state, navigate);
        }
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - licenses.length) : 0;

    const onClose = () => {
        setState({ isShowSnackbar: false });
    }

    return (
        <MainContainer>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
                <HeaderComponent text="all licenses" />

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Creation date</TableCell>
                                <TableCell align="left">Owner</TableCell>
                                <TableCell align="left">Reason</TableCell>
                                <TableCell align="left">Expiry date</TableCell>
                                <TableCell align="center">Download</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {licenses.slice().sort(getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((license, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{license.creationDate}</TableCell>
                                        <TableCell align="left">{license.owner}</TableCell>
                                        <TableCell align="left">{license.reason}</TableCell>
                                        <TableCell align="left">{license.expiryDate}</TableCell>
                                        <TableCell align="center"><DownloadIcon sx={{ cursor: 'pointer', transition: 'color .2s ease-in', "&:hover": { color: theme.palette.blueLight } }} onClick={() => onDownloadClicked(license.filename)} /></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="table"
                    count={licenses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <AlertDialog open={state.isShowSnackbar} body={state.snackbarMessage} onClose={onClose} />
            </Box>
        </MainContainer>
    )
}

export default AllLicenses;