import React from "react";
import { useTheme, Typography } from "@mui/material";

export const HeaderComponent = ({
    text,
    fontSize
}) => {
    const theme = useTheme();
    return (
        <Typography component="h1" sx={{ fontSize: fontSize ? fontSize : theme.fontSize.h5, fontWeight: '700', textTransform: 'uppercase', color: theme.palette.blueLight }}>
            {text}
        </Typography>
    )
}