import axios from 'axios';
import * as ErrorHandlerUiUtils from './ErrorHandlerUIUtils';

export const setUsersCache = (token, username) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("username", username);
}

export const setTokenToAxiosHeaders = (token) => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

export const responseSuccessfulGoogleLogin = async (response, setState) => {
    try {
        let serverResponse = await axios.post("https://build.eyeviation.com/be/users/google-login", { tokenId: response.credential });
        const serverResponseData = serverResponse.data;
        let userDetails = {
            token: serverResponseData.token,
            username: serverResponseData.username
        }

        setUsersCache(userDetails.token, userDetails.username);

        setTokenToAxiosHeaders(userDetails.token);
        return true;
    }
    catch (error) {
        ErrorHandlerUiUtils.handleErrorsOnUi(error, setState);
        return false;
    }
}

export const responseFailureGoogleLogin = (response, setState) => {
    console.log("error: ", response);
    ErrorHandlerUiUtils.handleErrorsOnUi(response, setState);
}