import { useEffect, useRef } from "react";
import * as LoginUtils from './LoginUtils';
import { Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export default function GoogleButton({ setState }) {
    const navigate = useNavigate();
    const divRef = useRef(null);
    const googleOAuthClientId = "1068320714807-bfnb5bukj9hdnkvo6ncl999e22vdgsvc.apps.googleusercontent.com";

    useEffect(() => {
        if (typeof window === "undefined" || !window.google || !divRef.current) {
            return;
        }

        try {
            window.google.accounts.id.initialize({
                client_id: googleOAuthClientId,
                hd: "eyeviation.com",
                callback: async (response) => {
                    let loginRes = await LoginUtils.responseSuccessfulGoogleLogin(response, setState);
                    if (loginRes)
                        navigate("downloads");
                },
            });

            window.google.accounts.id.renderButton(divRef.current, { width: "300px" });
        } catch (error) {
            LoginUtils.responseFailureGoogleLogin(error, setState);
        }
    }, [googleOAuthClientId, window.google, divRef.current]);

    return <Box ref={divRef} />;
}