import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import * as LoginUtils from '../../utils/LoginUtils';
import { useNavigate } from 'react-router-dom';

import { Header } from "./Header";
import { SideBar } from "./SideBar";

export const MainContainer = ({ children, props }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const logo = '/eyeviation_logo.svg';
    const menuItems = [{ text: 'Downloads', href: '/downloads' }, { text: 'License', href: '/create-license' }, { text: 'All Licenses', href: '/licenses' }, { text: 'Logout', icon: <LogoutIcon /> },]

    useEffect(() => {
        window.scrollTo(0, 0);

        //in order to 'survive' page refresh
        let token = sessionStorage.getItem("token");
        LoginUtils.setTokenToAxiosHeaders(token);

        if (!token && window.location.pathname.length > 0) {
            navigate("/");
        }
    }, [window.location.pathname]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Header logo={logo} />
            {window.location.pathname.split('/')[1] != '' && <SideBar menuItems={menuItems} />}
            <Box component="main" sx={{ flexGrow: 1, p: '5rem 2rem' }}>
                {children}
            </Box>
        </Box>
    );
};