export const handleErrorsOnUi = (error, setState, state, navigate) => {
    if (error.response !== undefined) {
        //this block handles the 404 code which comes back from the Axios > for example, when the path is wrong
        if (error.response.status === 404) {
            console.log(error.message);
            showSnackbarWithErrorStatus(error.response.status, error.message, setState);
            return;
        }

        //this block handles USER_NAME_ALREADY_EXIST and UNAUTHORIZED (invalid login details)
        if (error.response.status === 401 || error.response.status === 601) {
            console.log(error.response.status + ": " + error.response.data.error);
            showSnackbar(error.response.data.error, setState, state);
            return;
        }

        //this block handles GENERAL_ERROR and NO_VACATION_DATA
        if (error.response.status === 600 || error.response.status === 500) {
            console.log(error.response.status + ": " + error.response.data.error);
            showSnackbarWithErrorStatus(error.response.status, error.response.data.error, setState);
            return;
        }

        //this block handles INVALID_TOKEN
        if (error.response.status === 403) {
            console.log(error.response.status + ": " + error.response.data.error);
            showSnackbarWithErrorStatus(error.response.status, error.response.data.error, setState);
            // hideSnackbar(setState);
            sendBackToHomePage(navigate);
            return;
        }
    }

    console.log(error.message);
    showSnackbarWithErrorStatus("Unknown", error.message, setState);
    // hideSnackbar(setState);
}

export const showSnackbarWithErrorStatus = (errorStatus, errorMessage, setState) => {
    setState({ isShowSnackbar: true, snackbarMessage: errorStatus + ": " + errorMessage, isNavigateBack: errorStatus === 403 ? true : false });
}

export const showSnackbar = (errorMessage, setState, state) => {
    setState({ isShowSnackbar: true, snackbarMessage: errorMessage, isNavigateBack: false });
}

export const hideSnackbar = (setState) => {
    setTimeout(() => {
        setState({ isShowSnackbar: false, snackbarMessage: "", isNavigateBack: false });
    }, 2200)
}

export const sendBackToHomePage = (navigate) => {
    setTimeout(() => {
        sessionStorage.clear();
        navigate("/login");
    }, 2200)
}